//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex'
import phones from '~/mixins/phones'
import { applyLocale, objectDeepEqual } from '~/utils/v'
import flags from '~/mixins/flags'

export default {
  name: 'HeaderMobile',
  mixins: [phones, flags],
  data () {
    return {
      busy: {
        locale: false
      }
    }
  },
  methods: {
    getRegisterAction () {
      this.close()
      if (['auth'].includes(this.$nuxt.$data.layoutName)) {
        this.$router.push({ name: 'register' })
      } else {
        this.$modal.show('register-modal')
      }
    },
    getLoginAction () {
      this.close()
      if (['auth'].includes(this.$nuxt.$data.layoutName)) {
        this.$router.push({ name: 'login' })
      } else {
        this.$modal.show('login-modal')
      }
    },
    close () {
      const from = this.menu.cur

      switch (from) {
        case null:
        case 'user-menu':
          this.$emit('close')
          this.$store.commit('SET_MENU_TAB', { name: 'mobile', value: null })
          break
        case 'contact-menu':
          this.$emit('close')
          this.$store.commit('SET_MENU_TAB', { name: 'mobile', value: null })
          break
        default:
          this.$store.commit('SET_MENU_TAB', { name: 'mobile', value: null })
          break
      }
    },
    setLocale (item) {
      const locale = item.shortcode

      if (this.locale !== locale) {
        this.busy.locale = true
        this.close()
        applyLocale(this, locale)
      }
    },
    setCurrency (item) {
      this.$store.dispatch('lang/setCurrency', { currency: item.shortcode })
      this.close()
    },
    action (name, data = {}, params = {}) {
      switch (name) {
        case 'to-currency-menu':
          this.changeTab('currency-menu')
          break
        case 'to-lang-menu':
          this.changeTab('lang-menu')
          break
        case 'to-contact-menu':
          this.changeTab('contact-menu')
          break
        default:
          break
      }
    },
    checkTabExists (tabName) {
      return Object.keys(this.menu.tabs).includes(tabName)
    },
    changeTab (name, data = {}, params = {}) {
      this.$store.commit('SET_MENU_TAB', { name: 'mobile', value: name })
    }
  },
  computed: {
    ...mapState({
      MENU: state => state.menu,
      phoneNumbers: state => state.contact.phoneNumbers
    }),
    ...mapGetters({
      locale: 'lang/locale',
      currentCurrency: 'lang/currentCurrency',
      currentLanguage: 'lang/currentLanguage',
      currentCurrencyData: 'lang/currentCurrencyData',
      check: 'auth/check'
    }),
    activeLanguages () {
      return [...this.$store.getters['lang/locales']].sort((a, b) => {
        if (a.shortcode === this.locale) {
          return -1
        }
        return 0
      })
    },
    activeCurrencies () {
      return [...this.$store.getters['lang/currencies']].sort((a, b) => {
        if (a.shortcode === this.currentCurrencyData.shortcode) {
          return -1
        }
        return 0
      })
    },
    menu: {
      get () {
        return this.MENU.mobile
      },
      set (value) {
        this.$store.commit('SET_MENU', { mobile: value })
      }
    }
  },
  watch: {
    '$route' (to, from) {
      if (to.name === from.name && objectDeepEqual({ ...to.params, lang: '' }, { ...from.params, lang: '' })) {
        return
      }
      this.close()
      this.$emit('close')
    }
  }
}
