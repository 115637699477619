//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HeaderMobile from '~/components/header-mobile'
export default {
  name: 'MobileMenuModal',
  components: { HeaderMobile },
  methods: {
    close () {
      this.$modal.hide('mobile-menu-modal')
    },
    onBeforeOpen (obj) {
      if (!obj?.params) {
        return
      }
      if (obj.params?.tab) {
        this.$store.commit('SET_MENU_TAB', { name: 'mobile', value: obj.params?.tab })
      }
    }
  }
}
